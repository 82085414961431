import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

const translations = {
  en: {
    title: "Grow your business with AI",
    subtitle: "AI can transform your business and put you ahead of the competition. Here's why to use Uni AI.",
    items: [
      {
        title: '24/7 Availability',
        description: 'Offer continuous support and sales assistance, ensuring you never miss an opportunity.',
      },
      {
        title: 'Multilingual Support',
        description: 'Communicate effectively with customers in any language, ensuring a smooth user experience.',
      },
      {
        title: 'Great customer experience',
        description: 'Automate inquiries and transactions, making the sales process faster and more efficient.',
      },
      {
        title: 'Whatsapp Marketing',
        description: 'Engage customers directly on WhatsApp, their trusted platform. Our AI agent integrates seamlessly, making interactions easy and convenient.',
      },
      {
        title: 'Intelligent Automation',
        description: 'AI agents automate repetitive tasks, reducing operational costs',
      },
      {
        title: 'Data-driven insights',
        description: 'Analyze customer interactions and sales data to optimize your strategy and drive growth.',
      },
    ],
  },
  he: {
    title: "הגדילו את העסק שלכם עם בינה מלאכותית",
    subtitle: "בינה מלאכותית יכולה לשנות את העסק שלך ולהעמיד אותך בראש התחרות. הנה למה כדאי להשתמש ב-Uni AI.",
    items: [
      {
        title: 'זמינות 24/7',
        description: 'הציעו תמיכה ועזרה במכירות באופן רציף, כדי להבטיח שלא תחמיצו אף הזדמנות.',
      },
      {
        title: 'תמיכה רב-לשונית',
        description: 'תקשרו ביעילות עם לקוחות בכל שפה, תוך הבטחת חוויית משתמש חלקה.',
      },
      {
        title: 'חוויית לקוח מעולה',
        description: 'אוטומציה של שאילתות ועסקאות, הופכת את תהליך המכירה למהיר ויעיל יותר.',
      },
      {
        title: 'שיווק בוואטסאפ',
        description: 'התקשרו עם לקוחות ישירות בוואטסאפ, הפלטפורמה המהימנה שלהם. סוכן הבינה המלאכותית שלנו משתלב בצורה חלקה, הופך את האינטראקציות לקלות ונוחות.',
      },
      {
        title: 'אוטומציה חכמה',
        description: 'סוכני בינה מלאכותית מאוטמטים משימות חוזרות, מפחיתים עלויות תפעוליות',
      },
      {
        title: 'תובנות מבוססות נתונים',
        description: 'נתחו אינטראקציות עם לקוחות ונתוני מכירות כדי לייעל את האסטרטגיה שלכם ולהניע צמיחה.',
      },
    ],
  },
};

const icons = [
  <ThumbUpAltRoundedIcon />,
  <AutoFixHighRoundedIcon />,
  <SupportAgentRoundedIcon />,
  <ConstructionRoundedIcon />,
  <SettingsSuggestRoundedIcon />,
  <QueryStatsRoundedIcon />,
];

export default function Highlights({ language = 'en' }) {
  const t = translations[language];

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: 'background.paper',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h3" align={language === 'he' ? 'right' : 'left'} sx={{direction: language === 'he' ? 'rtl' : 'ltr', color: "text.primary" }}>
            {t.title}
          </Typography>
          <Typography variant="body1" sx={{ color:"text.secondary", direction: language === 'he' ? 'rtl' : 'ltr' }} align={language === 'he' ? 'right' : 'left'}>
            {t.subtitle}
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {t.items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '2px solid',
                  borderColor: 'palette.border.gray',
                  backgroundColor: 'brand.500',
                  color:"text.primary"
                }}
              >
                <Box sx={{ opacity: '50%' }}>{icons[index]}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom align={language === 'he' ? 'right' : 'left'} sx={{direction:language === 'he' ? 'rtl' : 'ltr'}}>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400', direction: language === 'he' ? 'rtl' : 'ltr'  }} align={language === 'he' ? 'right' : 'left'}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}