import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Masonry from '@mui/lab/Masonry';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

const translations = {
  en: {
    title: "What our customers say",
    subtitle: "See what our customers love about our products. Discover how we excel in efficiency, durability, and satisfaction. Join us for quality, innovation, and reliable support.",
  },
  he: {
    title: "מה הלקוחות שלנו אומרים",
    subtitle: "ראו מה הלקוחות שלנו אוהבים במוצרים שלנו. גלו כיצד אנו מצטיינים ביעילות, עמידות ושביעות רצון. הצטרפו אלינו לאיכות, וחדשנות.",
  }
};

const userTestimonials = [
  {
    avatar: <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />,
    name: 'Yosef',
    occupation: {
      en: 'Business Owner',
      he: 'בעל עסק'
    },
    testimonial: {
      en: "Using Uni's AI agent has been a game-changer for our business. Our customers love being able to chat and shop directly on WhatsApp, and the automated responses make managing inquiries a breeze. It's like having a personal assistant available 24/7!",
      he: "השימוש בסוכן הבינה המלאכותית של Uni שינה את המשחק עבור העסק שלנו. הלקוחות שלנו אוהבים את היכולת לשוחח ולקנות ישירות בוואטסאפ, והתשובות האוטומטיות הופכות את ניהול הפניות לקלות. זה כמו שיש עוזר אישי זמין 24/7!"
    },
  },
  {
    avatar: <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />,
    name: 'David',
    occupation: {
      en: 'Business Owner',
      he: 'בעל עסק'
    },
    testimonial: {
      en: "The ease of integrating Uni's AI with WhatsApp is incredible. We've seen a noticeable boost in sales and customer satisfaction. The multilingual support means we can cater to a diverse audience without any language barriers. Highly recommend!",
      he: "הקלות של שילוב הבינה המלאכותית של Uni עם וואטסאפ היא מדהימה. ראינו עלייה ניכרת במכירות ובשביעות רצון הלקוחות. התמיכה הרב-לשונית מאפשרת לנו לשרת קהל מגוון ללא מחסומי שפה. ממליץ בחום!"
    },
  },
  {
    avatar: <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />,
    name: 'Shara',
    occupation: {
      en: 'Business Owner',
      he: 'בעלת עסק'
    },
    testimonial: {
      en: "Uni's AI agent has simplified our sales process and made it so much more efficient. Our customers appreciate the quick and personalized responses, and we love that the system works around the clock. It's a must-have tool for any business looking to improve their customer engagement.",
      he: "סוכן הבינה המלאכותית של Uni פישט את תהליך המכירות שלנו והפך אותו ליעיל הרבה יותר. הלקוחות שלנו מעריכים את התגובות המהירות והמותאמות אישית, ואנחנו אוהבים שהמערכת עובדת סביב השעון. זהו כלי חובה לכל עסק המעוניין לשפר את מעורבות הלקוחות שלו."
    },
  },
];

const whiteLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628e8573c43893fe0ace_Sydney-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d520d0517ae8e8ddf13_Bern-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f46794c159024c1af6d44_Montreal-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e891fa22f89efd7477a_TerraLight.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a09d1f6337b1dfed14ab_colorado-white.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5caa77bf7d69fb78792e_Ankara-white.svg',
];

const darkLogos = [
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560628889c3bdf1129952dc_Sydney-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f4d4d8b829a89976a419c_Bern-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f467502f091ccb929529d_Montreal-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/61f12e911fa22f2203d7514c_TerraDark.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/6560a0990f3717787fd49245_colorado-black.svg',
  'https://assets-global.website-files.com/61ed56ae9da9fd7e0ef0a967/655f5ca4e548b0deb1041c33_Ankara-black.svg',
];

const logoStyle = {
  width: '64px',
  opacity: 0.3,
  margin: '16px'
};

export default function Testimonials({ language = 'en' }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const columns = isSmallScreen ? 1 : 3;
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;
  const direction = language === 'he' ? 'rtl' : 'ltr';

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        textAlign: direction, // Adjust text alignment based on language direction
        dir: direction, // Set text direction
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: direction, md: 'center' },
        }}
      >
        <Typography component="h2" variant="h3" sx={{ direction: language === 'he' ? 'rtl' : 'ltr', align: language === 'he' ? 'right' : 'left'}} color="text.primary" >
          {translations[language].title}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ direction: language === 'he' ? 'rtl' : 'ltr', align: language === 'he' ? 'right' : 'left'}}>
          {translations[language].subtitle}
        </Typography>
      </Box>
      <Masonry columns={columns} spacing={2}>
        {userTestimonials.map((testimonial, index) => (
         <Card key={index} sx={{ p: 1 }}>
         <CardContent>
           <Typography
             variant="body2"
             color="text.secondary"
             sx={{
               direction: language === 'he' ? 'rtl' : 'ltr',
               textAlign: language === 'he' ? 'right' : 'left',
             }}
           >
             {testimonial.testimonial[language]}
           </Typography>
         </CardContent> 
         <Box
           sx={{
             display: 'flex',
             flexDirection: language === 'he' ? 'row-reverse' : 'row',
             justifyContent: 'space-between',
           }}
         >
           <CardHeader
            avatar={testimonial.avatar}
            title={testimonial.name}
            subheader={testimonial.occupation[language]}
            sx={{
              direction: language === 'he' ? 'rtl' : 'ltr',
              '& .MuiCardHeader-avatar': {
                marginRight: language === 'he' ? '0px !important' : '16px !important',
                marginLeft: language === 'he' ? '16px !important' : '0px !important',
              },
            }}
            />
           <img src={logos[index]} alt={`Logo ${index + 1}`} style={logoStyle}/>
         </Box>
       </Card>       
        ))}
      </Masonry>
    </Container>
  );
}
