import * as React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TranslateIcon from '@mui/icons-material/Translate';
import AppAppBar from '../components/AppAppBar';
import Hero from '../components/Hero';
import LogoCollection from '../components/LogoCollection';
import Highlights from '../components/Highlights';
import Pricing from '../components/Pricing';
import Testimonials from '../components/Testimonials';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import getLPTheme from '../getLPTheme';
import ContactUs from '../components/contactUs';
import ProductBanner from '../components/productsBarner';
import FreeTrialPage from '../components/FreeTrialPage'
import AccessibilityWidget from '../components/UserWayAccessibilityWidget';


const defaultTheme = createTheme({});

function ToggleLanguage({ language, toggleLanguage }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 20,
        zIndex: 1000
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={language}
        onChange={toggleLanguage}
        aria-label="Language"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value="en">
          <TranslateIcon sx={{ fontSize: '20px', mr: 1 }} />
          English
        </ToggleButton>
        <ToggleButton value="he">
          <TranslateIcon sx={{ fontSize: '20px', mr: 1 }} />
          עברית
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleLanguage.propTypes = {
  language: PropTypes.string.isRequired,
  toggleLanguage: PropTypes.func.isRequired,
};

export default function LandingPage() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme] = React.useState(true);
  const [language, setLanguage] = React.useState('he');
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'he' : 'en'));
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar 
        mode={mode} 
        toggleColorMode={toggleColorMode} 
        language={language} 
        toggleLanguage={toggleLanguage}
      />
      <Hero language={language} />
      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <ProductBanner language={language} />
        <Highlights language={language} />
        <Divider />
        <LogoCollection language={language} />
        <Divider />
        <Testimonials language={language} />
        <Divider />
        <Pricing language={language} />
        <Divider />
        <FreeTrialPage language={language}/>
        <FAQ language={language} />
        <Divider />
        <ContactUs language={language} />
        <Divider />
        <Footer language={language} />
        <AccessibilityWidget />

      </Box>
          <ToggleLanguage language={language} toggleLanguage={toggleLanguage} />
    </ThemeProvider>
  );
}