import * as React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getLPTheme from '../getLPTheme';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TranslateIcon from '@mui/icons-material/Translate';
import AppAppBar from '../components/AppAppBar';
import Footer from '../components/Footer';
import UploadProdutcs from '../components/UploadProducts';
import AccessibilityWidget from '../components/UserWayAccessibilityWidget';


const defaultTheme = createTheme({});


function ToggleLanguage({ language, toggleLanguage }) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100dvw',
          position: 'fixed',
          bottom: 20,
          zIndex: 1000
        }}
      >
        <ToggleButtonGroup
          color="primary"
          exclusive
          value={language}
          onChange={toggleLanguage}
          aria-label="Language"
          sx={{
            backgroundColor: 'background.default',
            '& .Mui-selected': {
              pointerEvents: 'none',
            },
          }}
        >
          <ToggleButton value="en">
            <TranslateIcon sx={{ fontSize: '20px', mr: 1 }} />
            English
          </ToggleButton>
          <ToggleButton value="he">
            <TranslateIcon sx={{ fontSize: '20px', mr: 1 }} />
            עברית
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    );
  }
  
  ToggleLanguage.propTypes = {
    language: PropTypes.string.isRequired,
    toggleLanguage: PropTypes.func.isRequired,
  };

export default function DemoPage() {
    const [mode, setMode] = React.useState('light');
    const [showCustomTheme] = React.useState(true);
    const [language, setLanguage] = React.useState('he');
    const LPtheme = createTheme(getLPTheme(mode));
  
    const toggleColorMode = () => {
        setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
    };

  
    const toggleLanguage = () => {
        setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'he' : 'en'));
    };
  
    return (
        <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
            <CssBaseline />
            <AppAppBar
                mode={mode}
                toggleColorMode={toggleColorMode}
                language={language}
                toggleLanguage={toggleLanguage}
                mainPage={false}
            />
            <Box sx={{ bgcolor: 'background.default' }}>
                <UploadProdutcs language={language}/>
                <Divider />
                <Footer language={language}/>
            </Box>
            <AccessibilityWidget />
            <ToggleLanguage language={language} toggleLanguage={toggleLanguage} />
        </ThemeProvider>
    )
};