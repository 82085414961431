import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from './ToggleColorMode';
import ToggleLanguage from './ToggleLanguage';

import logo_image_no_name from './img/uni-logo.png';

const content = {
  en: {
    solutions: 'Solutions',
    highlights: 'Highlights',
    testimonials: 'Testimonials',
    pricing: 'Pricing',
    GiveUsATry: 'Give Us A Try',
    faq: 'FAQ',
    contactUs: 'Contact Us',
    features: 'Features'
  },
  he: {
    solutions: 'פתרונות',
    highlights: 'נקודות בולטות',
    testimonials: 'המלצות',
    pricing: 'תמחור',
    GiveUsATry: 'נסו אותנו',
    faq: 'שאלות נפוצות',
    contactUs: 'צור קשר',
    features: 'תכונות'
  },
};

function AppAppBar({ mode, toggleColorMode, language, toggleLanguage, mainPage = true }) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

    const scrollToSection = (sectionId) => {
        if (mainPage) {
            const sectionElement = document.getElementById(sectionId);
            const offset = 128;
            if (sectionElement) {
                const targetScroll = sectionElement.offsetTop - offset;
                sectionElement.scrollIntoView({ behavior: 'smooth' });
                window.scrollTo({
                    top: targetScroll,
                    behavior: 'smooth',
                });
                setOpen(false);
            }
        } else {
            window.location.href = `#${sectionId}`;
        }
    };

  // Ensure we have a valid language, defaulting to 'en' if not
  const currentLanguage = content[language] ? language : 'en';

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor: 'primary.main', // Changed this line
              backdropFilter: 'blur(24px)',
              maxHeight: 70,
              minHeight:50,
              border: 'none',
              borderColor: 'divider',
              paddingLeft:'0px !important',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
           <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              px: 0,
              height: 'auto',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={logo_image_no_name}
                style={{ height: '70px', width: 'auto', marginRight: '3px' }}
                alt="logo of Uni Platforms"
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant="h5"
                  noWrap
                  component="div"
                  sx={{
                    flexGrow: 1,
                    color: 'text.light',
                  }}
                >
                  Uni Platforms
                </Typography>
                
                <Typography
                  variant="miniBody"
                  noWrap
                  component="div"
                  sx={{
                    color: 'text.light',
                    marginTop: '-5px',
                    marginLeft: '2px'
                  }}
                >
                  AI solutions for SMBs
                </Typography>
              </Box>
            </Box>
          </Box>
            
            <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', height: '100%' }}>
              {['solutions', 'highlights', 'testimonials', 'pricing', 'GiveUsATry', 'faq', 'contactUs'].map((item) => (
                <MenuItem
                  key={item}
                  onClick={() => scrollToSection(item)}
                  sx={{ 
                    py: '6px', 
                    px: '12px',
                    height: '100%', // Make menu items full height
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body2" color="text.light">
                    {content[currentLanguage][item]}
                  </Typography>
                </MenuItem>
              ))}
            </Box>
            
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />
              <ToggleLanguage language={currentLanguage} toggleLanguage={toggleLanguage} />
            </Box>
            
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ height: 'auto', width:'35px', p: '4px' }}
              >
                <MenuIcon sx={{ fontSize: 40 }}/>
              </Button>
              
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={(theme) => ({
                    minWidth: '80dvw',
                    p: 2,
                    backgroundImage: theme.palette.background.image,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    flexGrow: 1,
                    direction: currentLanguage === 'he' ? 'rtl' : 'ltr', // Add RTL for Hebrew
                  })} 
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row', // Change to row to place toggles side by side
                      justifyContent: 'flex-start', // This will push the toggles to the start of the flex container
                      width: '100%', // Ensure the box takes full width
                      mb: 2, // Add some margin at the bottom for separation
                    }}
                  >
                    <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} sx={{ mr: 1 }} />
                    <ToggleLanguage language={currentLanguage} toggleLanguage={toggleLanguage} />
                  </Box>

                  {['features', 'testimonials', 'highlights', 'pricing', 'GiveUsATry', 'faq', 'contactUs'].map((item) => (
                    <MenuItem key={item} onClick={() => scrollToSection(item)}>
                      {content[currentLanguage][item]}
                    </MenuItem>
                  ))}
                  <Divider />
                </Box>
              </Drawer>
            
            </Box>
          
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
  language: PropTypes.oneOf(['en', 'he']).isRequired,
  toggleLanguage: PropTypes.func.isRequired,
};

export default AppAppBar;
