import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TranslateIcon from '@mui/icons-material/Translate';
import Box from '@mui/material/Box';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';

export default function ToggleLanguage({ language, toggleLanguage }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Button
      variant="text"
      onClick={toggleLanguage}
      color={isDesktop ? 'primary' : 'secondary' } // Conditionally set color
      sx={{
        direction: language === 'he' ? 'rtl' : 'ltr',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {language === 'he' ? (
          <>
            {language === 'en' ? 'EN' : 'עב'}
            <TranslateIcon sx={{ ml: 1 }} />
          </>
        ) : (
          <>
            <TranslateIcon sx={{ mr: 1 }} />
            {language === 'en' ? 'EN' : 'עב'}
          </>
        )}
      </Box>
    </Button>
  );
}

ToggleLanguage.propTypes = {
  language: PropTypes.oneOf(['en', 'he']).isRequired,
  toggleLanguage: PropTypes.func.isRequired,
};
