import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter'; // Corrected icon name
import InstagramIcon from '@mui/icons-material/Instagram'; // Corrected icon name

import uniLogo from './img/uni-logo-with-name.svg';

const logoStyle = {
  width: '140px',
  height: 'auto',
};

const translations = {
  en: {
    newsletter: 'Newsletter',
    subscribeText: 'Subscribe to our newsletter for weekly updates and promotions.',
    emailPlaceholder: 'Your email address',
    subscribeButton: 'Subscribe',
    product: 'Product',
    solutions: 'Solutions',
    testimonials: 'Testimonials',
    highlights: 'Highlights',
    pricing: 'Pricing',
    faqs: 'FAQs',
    legal: 'Legal',
    terms: 'Terms',
    privacy: 'Privacy',
    contact: 'Contact',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Terms of Service',
  },
  he: {
    newsletter: 'ניוזלטר',
    subscribeText: 'הירשמו לניוזלטר שלנו לעדכונים שבועיים ומבצעים.',
    emailPlaceholder: 'כתובת האימייל שלך',
    subscribeButton: 'הרשמה',
    product: 'מוצר',
    solutions: 'פתרונות',
    testimonials: 'המלצות',
    highlights: 'הדגשים',
    pricing: 'תמחור',
    faqs: 'שאלות נפוצות',
    legal: 'משפטי',
    terms: 'תנאים',
    privacy: 'פרטיות',
    contact: 'צור קשר',
    privacyPolicy: 'מדיניות פרטיות',
    termsOfService: 'תנאי שימוש',
  },
};

function Copyright({ language }) {
  const currentYear = new Date().getFullYear();
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {language === 'he' ? (
        <>
          {"זכויות יוצרים © "}
          <Link href="https://mui.com/">Uni </Link>
          {currentYear}
        </>
      ) : (
        <>
          {'Copyright © '}
          <Link href="https://mui.com/">Uni </Link>
          {currentYear}
        </>
      )}
    </Typography>
  );
}

export default function Footer({ language = 'en' }) {
  const t = translations[language];
  const isRTL = language === 'he';

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: isRTL ? 'right' : 'left' },
        direction: isRTL ? 'rtl' : 'ltr',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <Box sx={{ ml: isRTL ? '15px' : '-15px' }}>
              <img
                src={uniLogo}
                style={logoStyle}
                alt="logo of sitemark"
              />
            </Box>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              {t.newsletter}
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              {t.subscribeText}
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label={t.emailPlaceholder}
                placeholder={t.emailPlaceholder}
                inputProps={{
                  autoComplete: 'off',
                  "aria-label": t.emailPlaceholder,
                }}
              />
              <Button variant="contained" color="secondary" sx={{ flexShrink: 0 }}>
                {t.subscribeButton}
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            {t.product}
          </Typography>
          <Link color="text.secondary" href="#solutions">
            {t.solutions}
          </Link>
          <Link color="text.secondary" href="#testimonials">
            {t.testimonials}
          </Link>
          <Link color="text.secondary" href="#highlights">
            {t.highlights}
          </Link>
          <Link color="text.secondary" href="#pricing">
            {t.pricing}
          </Link>
          <Link color="text.secondary" href="#faq">
            {t.faqs}
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            {t.legal}
          </Typography>
          <Link color="text.secondary" href="https://chat.uni-chat.app/terms-of-service">
            {t.terms}
          </Link>
          <Link color="text.secondary" href="https://chat.uni-chat.app/terms-of-service">
            {t.privacy}
          </Link>
          <Link color="text.secondary" href="#contactUs">
            {t.contact}
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="https://chat.uni-chat.app/terms-of-service">
            {t.privacyPolicy}
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="https://chat.uni-chat.app/terms-of-service">
            {t.termsOfService}
          </Link>
          <Copyright language={language} />
        </div>
        <Stack
          direction="row"
          justifyContent={isRTL ? 'right' : 'left'}
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.facebook.com/profile.php?id=61553699083573"
            aria-label="Facebook"
            sx={{ alignSelf: 'center' }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://x.com/Uni_platform"
            aria-label="Twitter" // Corrected aria-label
            sx={{ alignSelf: 'center' }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/uni-platform/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/uniplatforms/"
            aria-label="Instagram"
            sx={{ alignSelf: 'center' }}
          >
            <InstagramIcon /> {/* Corrected icon name */}
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
