import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import getLPTheme from '../getLPTheme';

const theme = createTheme(getLPTheme('light'));

const translations = {
  en: {
    title: "Business Details",
    description: "To receive proposals and analyze the content, please enter some details about the business.",
    simpleLanguage: "Write in the simplest language possible. We understand everything😉",
    placeholders: {
      businessName: "Business Name",
      fieldOfWork: "Field of Work",
      contactName: "Owner or Contact Name",
      phoneNumber: "Phone Number",
      email: "Email Address for Orders"
    },
    buttonLabel: "Go to WhatsApp",
    confirmationMessage: "Your details have been submitted!",
    goToWhatsApp: "Go to WhatsApp",
    errors: {
      required: "This field is required",
      invalidEmail: "Please enter a valid email address",
      invalidPhone: "Please enter a valid phone number"
    }
  },
  he: {
    title: "פרטי העסק",
    description: "לצורך קבלת הצעות, וניתוח התוכן הכנס כמה פרטים על העסק.",
    simpleLanguage: "כתוב בשפה הכי פשוטה. אנחנו מבינים הכל😉",
    placeholders: {
      businessName: "שם העסק",
      fieldOfWork: "תחום עיסוק",
      contactName: "שם בעלים או איש קשר",
      phoneNumber: "מספר טלפון",
      email: "כתובת אימייל להזמנות"
    },
    buttonLabel: "יאללה לוואטסאפ",
    confirmationMessage: "הפרטים שלך נשלחו!",
    goToWhatsApp: "לך לוואטסאפ",
    errors: {
      required: "שדה זה הוא חובה",
      invalidEmail: "נא להכניס כתובת אימייל חוקית",
      invalidPhone: "נא להכניס מספר טלפון חוקי"
    }
  }
};

const BusinessForm = ({ language = 'he' }) => {
  const [formData, setFormData] = useState({
    businessName: '',
    fieldOfWork: '',
    contactName: '',
    phoneNumber: '',
    email: ''
  });
  const [errors, setErrors] = useState({});
  const [wtsp, setWtsp] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const t = translations[language];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const wtspFromUrl = params.get('w');

    if (wtspFromUrl) {
      setWtsp(wtspFromUrl);
    }
  }, []);

  const validate = () => {
    const newErrors = {};
    
    if (!formData.businessName) newErrors.businessName = t.errors.required;
    if (!formData.fieldOfWork) newErrors.fieldOfWork = t.errors.required;
    if (!formData.contactName) newErrors.contactName = t.errors.required;
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = t.errors.required;
    } else if (!/^\+?\d{7,15}$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = t.errors.invalidPhone;
    }
    if (!formData.email) {
      newErrors.email = t.errors.required;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = t.errors.invalidEmail;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validate()) return;

    setIsSubmitting(true);

    const scriptURL = 'https://script.google.com/macros/s/AKfycbx4kYz8E67EUPQXGYtUnYV2KLZFi5roU4KWqW36Odxj5bBDbSqhC_tjx-wGYS9vxlWY/exec';
  
    try {
      await fetch(scriptURL, {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'no-cors',
      });
      
      setIsSubmitting(false);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error!', error.message);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleGoToWhatsApp = () => {
    window.location.href = `https://wa.me/${wtsp}`;
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          maxWidth: 400,
          margin: '0 auto',
          padding: 3,
          borderRadius: 2,
          background: theme.palette.background.default,
          boxShadow: 3,
          textAlign: language === 'he' ? 'right' : 'left',
          direction: language === 'he' ? 'rtl' : 'ltr',
          mb: 3
        }}
      >
        {!isSubmitted ? (
          <>
            <Typography variant="h4" component="h1" gutterBottom>
              {t.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t.description}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t.simpleLanguage}
            </Typography>

            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                name="businessName"
                placeholder={t.placeholders.businessName}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                onChange={handleInputChange}
                error={Boolean(errors.businessName)}
                helperText={errors.businessName}
              />
              <TextField
                fullWidth
                name="fieldOfWork"
                placeholder={t.placeholders.fieldOfWork}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                onChange={handleInputChange}
                error={Boolean(errors.fieldOfWork)}
                helperText={errors.fieldOfWork}
              />
              <TextField
                fullWidth
                name="contactName"
                placeholder={t.placeholders.contactName}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                onChange={handleInputChange}
                error={Boolean(errors.contactName)}
                helperText={errors.contactName}
              />
              <TextField
                fullWidth
                name="phoneNumber"
                placeholder={t.placeholders.phoneNumber}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                onChange={handleInputChange}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber}
              />
              <TextField
                fullWidth
                name="email"
                placeholder={t.placeholders.email}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                onChange={handleInputChange}
                error={Boolean(errors.email)}
                helperText={errors.email}
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ mt: 2 }}
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : t.buttonLabel}
              </Button>
            </Box>
          </>
        ) : (
          <Box textAlign="center">
            <CheckCircleIcon color="success" sx={{ fontSize: 40 }} />
            <Typography variant="h6" gutterBottom>
              {t.confirmationMessage}
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleGoToWhatsApp}>
              {t.goToWhatsApp}
            </Button>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default BusinessForm;
