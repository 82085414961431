import React from 'react';
import frontGif from './img/Minimal Gradient Modern Animated Marketing Instagram Reel (3).gif';

import {
  Box,
  Container,
  Typography,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { ArrowForward, ArrowBack } from '@mui/icons-material';


const translations = {
  en: {
    overline: "Track Audience Activities",
    title: "WhatsApp Store",
    description: "Uni WhatsApp Store enables You to sell their products and services directly to customers through WhatsApp, using natural language conversations.",
    features: [
      {
        number: '01',
        title: 'Meet Customers Where They Are',
        description: 'Engage customers directly on WhatsApp, their trusted platform. Our AI agent integrates seamlessly, making interactions easy and convenient.',
      },
      {
        number: '02',
        title: 'Easy to Use and Multilingual',
        description: 'Enjoy a user-friendly experience that works in any language, ensuring smooth communication with customers worldwide.',
      },
      {
        number: '03',
        title: '24/7 Customer Support',
        description: 'Our AI agent is available around the clock, providing instant responses and personalized assistance to customers at any time.',
      },
    ],
    buildYourOwnButton: "Give us a try",
  },
  he: {
    overline: "עקוב אחר פעילויות הקהל",
    title: "חנות וואטסאפ",
    description: "חנות וואטסאפ של Uni מאפשרת לכם למכור את המוצרים והשירותים שלכם ישירות ללקוחות דרך וואטסאפ, באמצעות שיחות בשפה טבעית.",
    features: [
      {
        number: '01',
        title: 'פגשו את הלקוחות במקום שהם נמצאים',
        description: 'התקשרו עם לקוחות ישירות בוואטסאפ, הפלטפורמה המהימנה שלהם. הסוכן הAI שלנו משתלב בצורה חלקה, הופך את האינטראקציות לקלות ונוחות.',
      },
      {
        number: '02',
        title: 'קל לשימוש ורב-לשוני',
        description: 'תיהנו מחוויה ידידותית למשתמש שעובדת בכל שפה, מבטיחה תקשורת חלקה עם לקוחות בכל העולם.',
      },
      {
        number: '03',
        title: 'תמיכת לקוחות 24/7',
        description: 'סוכן הAI שלנו זמין סביב השעון, מספק תגובות מיידיות וסיוע מותאם אישית ללקוחות בכל עת.',
      },
    ],
    buildYourOwnButton: "נסו אותנו",
  },
};


export default function ProductBanner({ language = 'en' }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const t = translations[language];
  const isRTL = language === 'he';


  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const offset = 80;
      const elementPosition = sectionElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  
    return (
      <Box
        id="solutions"
        sx={{
          width: '100%',
          minHeight: '100vh',
          bgcolor: 'background.paper',
          py: 8,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={4}
            alignItems="center"
            direction={language === 'he' ? 'row-reverse' : 'row'}
          >
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-start',
                alignItems: 'center',
                mb: '40px',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '80%',
                  paddingBottom: '80%',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '2%',
                    left: '12%',
                    right: '12%',
                    bottom: '2%',
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: '30px',
                    zIndex: 0,
                  },
                }}
              >
                <Box
                  component="img"
                  src={frontGif}
                  alt="App Demo GIF"
                  sx={{
                    zIndex: 1,
                    objectFit: 'contain',
                    width: 'auto',
                    height: '140%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography
                component="h2"
                variant="h3"
                color="text.primary"
                gutterBottom
                sx={{ fontWeight: 'bold', direction: language === 'he' ? 'rtl' : 'ltr' }}
                align={language === 'he' ? 'right' : 'left'}
              >
                {t.title}
              </Typography>
  
              <Typography
                variant="body1"
                color="text.secondary"
                paragraph
                align={language === 'he' ? 'right' : 'left'}
                sx={{direction: language === 'he' ? 'rtl' : 'ltr' }}
              >
                {t.description}
              </Typography>
  
              <Box sx={{ mt: 4 }}>
                {t.features.map((feature, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      mb: 2,
                      flexDirection: language === 'he' ? 'row-reverse' : 'row',
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="primary"
                      sx={{ mx: 2, fontWeight: 'bold' }}
                    >
                      {feature.number}
                    </Typography>
                    <Box
                      sx={{
                        textAlign: language === 'he' ? 'right' : 'left',
                        direction: language === 'he' ? 'rtl' : 'ltr',
                      }}
                    >
                      <Typography variant="subtitle1" color="text.secondary">
                        {feature.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {feature.description}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', width:'100%', marginLeft:'24px'}}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              component='a'
              onClick={() => scrollToSection('GiveUsATry')}
              sx={{
                borderRadius: '50px',
                padding: '12px 24px',
                direction: isRTL ? 'rtl' : 'ltr',
                '& .MuiButton-endIcon': {
                  marginLeft: isRTL ? 0 : '8px',
                  marginRight: isRTL ? '8px' : 0,
                },
              }}
              endIcon={language !== 'he' ? <ArrowForward /> : <ArrowBack />}
            >
              {t.buildYourOwnButton}
            </Button>
          </Box>
          </Grid>
        </Container>
        
        {!isMobile && (
          <Box
            sx={{
              position: 'absolute',
              top: -100,
              right: language === 'he' ? 'auto' : -100,
              left: language === 'he' ? -100 : 'auto',
              width: 200,
              height: 200,
              borderRadius: '50%',
              backgroundColor: theme.palette.secondary.main,
              opacity: 0.1,
            }}
          />
        )}
      </Box>
    );
  };  