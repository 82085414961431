import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const translations = {
  en: {
    title: "Frequently asked questions",
    questions: [
      {
        question: "How quickly can I get started with the AI agent?",
        answer: "You can get started with our AI agent in just 3 simple steps. Open a WhatsApp account, upload your catalog, connect payments and you're ready to sell on WhatsApp. The process typically takes between 2 - 7 days."
      },
      {
        question: "Is it connected to my payment processor and POS?",
        answer: "Yes, our AI agent can connect to your existing payment processor and POS system, allowing for smooth and efficient transactions."
      },
      {
        question: "Is the AI agent easy to use for non-technical staff?",
        answer: "Absolutely! Our platform is designed with user-friendliness in mind, requiring no technical expertise. Your team can easily manage and interact with customers through a simple interface."
      },
      {
        question: "Can I use my existing WhatsApp account?",
        answer: "No, you need to open a WhatsApp Cloud API account to use our AI agent. This ensures a seamless and scalable integration."
      }
    ]
  },
  he: {
    title: "שאלות נפוצות",
    questions: [
      {
        question: "כמה מהר אני יכול להתחיל עם סוכן הבינה המלאכותית?",
        answer: "אתה יכול להתחיל עם סוכן הבינה המלאכותית שלנו ב-3 שלבים פשוטים. פתח חשבון וואטסאפ, העלה את הקטלוג שלך, חבר תשלומים ואתה מוכן למכור בוואטסאפ. התהליך לוקח בין 2 ל-7 ימים."
      },
      {
        question: "האם זה מחובר למעבד התשלומים וה-POS שלי?",
        answer: "כן, סוכן הבינה המלאכותית שלנו יכול להתחבר למעבד התשלומים ומערכת ה-POS הקיימים שלך, מה שמאפשר עסקאות חלקות ויעילות."
      },
      {
        question: "האם סוכן הבינה המלאכותית קל לשימוש עבור צוות שאינו טכני?",
        answer: "בהחלט! הפלטפורמה שלנו מעוצבת עם חשיבה על קלות השימוש, ואינה דורשת מומחיות טכנית. הצוות שלך יכול בקלות לנהל ולתקשר עם לקוחות דרך ממשק פשוט."
      },
      {
        question: "האם אני יכול להשתמש בחשבון הוואטסאפ הקיים שלי?",
        answer: "לא, אתה צריך לפתוח חשבון WhatsApp Cloud API כדי להשתמש בסוכן הבינה המלאכותית שלנו. זה מבטיח אינטגרציה חלקה וניתנת להרחבה."
      }
    ]
  }
};

export default function FAQ({ language = 'en' }) {
  const [expanded, setExpanded] = React.useState(false);
  const isRTL = language === 'he';
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        direction: isRTL ? 'rtl' : 'ltr'
      }}
    >
      <Typography
        component="h2"
        variant="h3"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
          direction: isRTL ? 'rtl' : 'ltr'
        }}
      >
        {translations[language].title}
      </Typography>
      <Box sx={{ width: '100%' }}>
        {translations[language].questions.map((item, index) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}d-content`}
              id={`panel${index + 1}d-header`}
            >
              <Typography component="h3" variant="subtitle2" sx={{ direction: isRTL ? 'rtl' : 'ltr' }}>
                {item.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '70%' }, direction: isRTL ? 'rtl' : 'ltr' }}
              >
                {item.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
}
