import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import frontGif from './img/frontVideo.gif';

const translations = {
  en: {
    companyName: "Uni Platforms",
    title: "AI solutions for every SMBs.",
    description: "specializes in AI-powered software to enhance customer interactions, enabling natural conversations for improved user experience and increased sales for small and medium businesses.",
    contactSales: "Contact Sales",
    requestDemo: "Our Solutions",
  },
  he: {
    companyName: "יוני פלאטפורמס",
    title: "פתרונות בינה מלאכותית לעסקים קטנים",
    description: "מתמחים בפיתוח פתרונות לעסקים קטנים עם בינה מלאכותית, לצורך יעילות הקשרי לקוחות, מכירות ועוד. הפלאטפורמות שלנו כבר משרתות עסקים מסביב לגלובוס במדינות כמו ארצות הברית, ספרד וישראל.",
    contactSales: "צור קשר עם המכירות",
    requestDemo: "הפתרונות שלנו",
  },
};

export default function Hero({ language = 'en' }) {
  const t = translations[language];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isIpad = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const offset = 80;
      const elementPosition = sectionElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Box
      id="hero"
      sx={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: theme.palette.background.image,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        pt: { xs: 4, sm: 10 },
        pb: { xs: 4, sm: 12 },
        textAlign: language === 'he' ? 'right' : 'left',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} alignItems="center" direction={language === 'he' ? 'row-reverse' : 'row'}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                position: 'relative',
                width: isDesktop ? 'auto' : '100%',
                height: isMobile ? '200px' : isIpad ? '250px' : '350px',
                mb: 2,
                ...(isDesktop && {
                  mt: 15,
                  transform: 'translateY(-50%)',
                  ...(language === 'he' ? { left: '20vw', right: 'auto' }:  { right: '20vw', left: 'auto' }),
                  position: 'absolute',
                }),
              }}
            >
              <Box
                component="img"
                src={frontGif}
                alt="App Demo GIF"
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 'auto',
                  height: '85%',
                  borderRadius: '50%',
                  zIndex: 1000,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: isMobile ? '200px' : isIpad ? '250px' : '350px',
                  height: isMobile ? '200px' : isIpad ? '250px' : '350px',
                  backgroundColor: 'white',
                  borderRadius: '50%',
                }}
              />
            </Box>
            
            <Typography
              component="h1"
              variant="h2"
              sx={{
                fontWeight: 'bold',
                textAlign: language === 'he' ? 'right' : 'left',
                direction: language === 'he' ? 'rtl' : 'ltr',
                mb: -1,
                color: theme.palette.mode === 'light' ? 'text.secondary' : 'text.secondary',
              }}
            >
              {t.companyName}
            </Typography>
            
            <Typography
              variant="subtitle1"
              sx={{ mb: 4, color: 'text.secondary', textAlign: language === 'he' ? 'right' : 'left', direction: language === 'he' ? 'rtl' : 'ltr' }}
            >
              {t.description}
            </Typography>
            
            <Stack direction="row" spacing={2} justifyContent={language === 'he' ? 'flex-end' : 'flex-start'}>
              <Button 
                variant="contained" 
                color="primary" 
                size="large" 
                startIcon={language === 'he' ? <ArrowBack /> : null} 
                endIcon={language !== 'he' ? <ArrowForward /> : null}
                onClick={() => scrollToSection('contactUs')}
              >
                {t.contactSales}
              </Button>
              <Button 
                variant="outlined" 
                size="large" 
                startIcon={language === 'he' ? <ArrowBack /> : null} 
                endIcon={language !== 'he' ? <ArrowForward /> : null}
                onClick={() => scrollToSection('solutions')}
              >
                {t.requestDemo}
              </Button>
            </Stack>
          
          </Grid>
        
        </Grid>
      </Container>
    </Box>
  );
}
