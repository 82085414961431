import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Paper,
  InputAdornment,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';

const translations = {
  he: {
    step: "שלב 1/2",
    title: "העלאת מוצרים",
    subtitle1: "לצורך בניית חנות חדשה, העלו את המוצרים שאתם רוצים שיהיו בחנות.",
    subtitle2: "המלצה שלנו, העלו לפחות 5 מוצרים.",
    uploadImage: "העלו תמונה",
    chooseImage: "בחירת תמונה",
    productName: "שם פריט",
    shortDescription: "תיאור קצר",
    price: "מחיר",
    addProduct:  "הוספת מוצר",
    startOrder: "להתחיל הזמנה",
    addNewProduct: "הוספת מוצר נוסף",
    errorMessage: "נא למלא את כל השדות ולהעלות תמונה.",
    currency: "מטבע",
        productAddedSuccessfully: "המוצר הועלה בהצלחה!",
        howToContinue: "איך תרצו להמשיך?",
  },
  en: {
        step: "Step 1/2",
        title: "Upload Products",
        subtitle1: "To build a new store, upload the products you want in the store.",
        subtitle2: "Our recommendation: upload at least 5 products.",
        uploadImage: "Upload Image",
        chooseImage: "Choose Image",
        productName: "Product Name",
        shortDescription: "Short Description",
        price: "Price",
        addProduct: "Add Product",
        startOrder: "Start To Order",
        addNewProduct: "Add Another Product",
        errorMessage: "Please fill in all fields and upload an image.",
      currency: "Currency",
      productAddedSuccessfully: "Product added successfully!",
        howToContinue: "How would you like to continue?",
    
  }
};

const RTLTextField = styled(TextField)(({ isrtl }) => ({
  '& .MuiInputBase-input': {
    textAlign: isrtl ? 'right' : 'left',
    direction: isrtl ? 'rtl' : 'ltr',
  },
  '& .MuiInputLabel-root': {
    top: '50%',
    transform: 'translateY(-50%)',
    left: isrtl ? 'inherit' : '20px',
    right: isrtl ? '20px' : 'inherit',
    transformOrigin: isrtl ? 'right' : 'left',
    transition: 'transform 0.2s ease-out, top 0.2s ease-out',
  },
  '& .MuiInputLabel-shrink': {
    top: 0,
    transform: isrtl
      ? 'translate(-14px, -9px) scale(0.75)'
      : 'translate(14px, -9px) scale(0.75)',
  },
  '& .MuiOutlinedInput-root': {
    minWidth: '0 !important',
    '&.Mui-focused': {
      outline: 'none !important',
      borderColor: 'transparent',
      boxShadow: 'none',
    },
  },
}));

const StyledFormControl = styled(FormControl)(({ isrtl }) => ({
    textAlign: isrtl ? 'right' : 'left',
    direction: isrtl ? 'rtl' : 'ltr',
    borderRadius: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    border: '1px solid #D6E2EB',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'border-color 120ms ease-in',
  
    height: '40px', // Match height of RTLTextField
    '& .MuiSelect-select': {
      borderRadius: '10px',
      padding: '16.5px 14px', // Adjust padding to fit height
      height: '100%', // Ensure height covers the full container
      display: 'flex',
      alignItems: 'center', // Vertically center content
    },
    '& .MuiInputLabel-root': {
        left: isrtl ? 'inherit' : '50px',
        right: isrtl ? '50px' : 'inherit',
        transformOrigin: isrtl ? 'right' : 'left',
        top: '-7px',
    },
    '& .MuiSelect-icon': {
      right: isrtl ? '14px' : 'initial',
      left: isrtl ? 'initial' : '14px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none', // Remove default outline
    },
    '& .MuiInputBase-formControl': {
        height: '40px',
    },
}));



const uploadImageToImgBB = async (file) => {
    if (!file) {
        console.error('No file provided for upload.');
        return;
    }

    const apiKey = "7ddff4fdf425bf4ad51cbaf8e9a1f5d5";
    const expiration = 600; // Optional: image will expire after 600 seconds (10 minutes)
    
    const formData = new FormData();
    formData.append("image", file); // Append the image file

    const url = `https://api.imgbb.com/1/upload?expiration=${expiration}&key=${apiKey}`;

    const requestOptions = {
        method: "POST",
        body: formData,
    };

    try {
        console.log('Uploading image to ImgBB...');
        const response = await fetch(url, requestOptions);

        console.log('Response Status:', response.status);
        const result = await response.json();

        if (!response.ok) {
            console.error('Error response:', result);
            throw new Error(`HTTP error! Status: ${response.status}, Message: ${result.data.error}`);
        }

        console.log('Upload successful:', result);
        return result.data.url; // Return the direct URL to the uploaded image
    } catch (error) {
        console.error('Error uploading image to ImgBB:', error.message);
        throw error; // Rethrow the error to handle it in the calling function
    }
};


const ProductUploadForm = ({ language = 'en' }) => {
    const t = translations[language];
    const isRTL = language === 'he';
    

    const currencyOptions = [
        { label: '₪ NIS', value: 'NIS' },
        { label: '$ USD', value: 'USD' },
        { label: '€ EUR', value: 'EUR' },
    ];

    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [currency, setCurrency] = useState('');
    const [error, setError] = useState(false);
    const [cid, setCid] = useState('');
    const [wtsp, setWtsp] = useState('');
    const [loading, setLoading] = useState(false);  // For loading state
    const [submitted, setSubmitted] = useState(false);  // For success state

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const cidFromUrl = params.get('cid');
    const wtspFromUrl = params.get('w');
    if (cidFromUrl) {
        setCid(cidFromUrl);
    }
    if (wtspFromUrl) {
        setWtsp(wtspFromUrl);
    }
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!image || !name || !description || !price || !currency) {
      setError(true);
      return;
    }

    setError(false);
    setLoading(true);

    try {
      const imageUrl = await uploadImageToImgBB(image);
      if (!imageUrl) return;

      const jsonData = {
        name,
        description,
        price: parseFloat(price) * 100,
        currency,
        image_url: imageUrl,
      };

      await axios.post(`https://dtu6mlq4yfv42e6k4qc23juibm0peftm.lambda-url.us-east-1.on.aws/catalog/${cid}/demo_upload`, jsonData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setLoading(false);
      setSubmitted(true);  // Set the form as submitted

    } catch (error) {
      console.error('Error uploading product:', error);
      setLoading(false);
    }
  };

  const handleAddAnotherProduct = () => {
    setSubmitted(false);
    setImage(null);
    setName('');
    setDescription('');
    setPrice('');
    setCurrency('');
  };

  const handleStartOrder = () => {
    window.location.href = `/demo/business-details?w=${wtsp}`; // Replace with the correct URL
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4, mt: 12, textAlign: isRTL ? 'right' : 'left' }}>
        {submitted ? (
          <Paper elevation={3} sx={{ p: 4, mt: 3, textAlign: 'center' }}>
            <CheckCircleIcon color="success" sx={{ fontSize: 50 }} />
            <Typography variant="body1" sx={{ mt: 2, direction: isRTL ? 'rtl' : 'ltr' }}>
                {t.productAddedSuccessfully}
            </Typography>
            <Typography variant="h5" sx={{ mt: 2, direction: isRTL ? 'rtl' : 'ltr' }}>
                {t.howToContinue}
            </Typography>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              sx={{ mt: 4 }}
              onClick={handleAddAnotherProduct}
            >
              {t.addNewProduct}
            </Button>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={handleStartOrder}
            >
              {t.startOrder}
            </Button>
          </Paper>
        ) : (
          <>
            <Typography
              variant="body1"
              component="p"
              gutterBottom
              align="center"
              sx={{ mb: 0, mt: 12, direction: isRTL ? 'rtl' : 'ltr', fontWeight: 'bold', color: "primary.dark" }}
            >
              {t.step}
            </Typography>
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              align="center"
              color="primary"
              sx={{ mt: -1, mb: 0, direction: isRTL ? 'rtl' : 'ltr' }}
            >
              {t.title}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              align="center"
              sx={{ color: "primary.dark", direction: isRTL ? 'rtl' : 'ltr', mb: 0 }}
            >
              {t.subtitle1}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              align="center"
              sx={{ mt: 0, direction: isRTL ? 'rtl' : 'ltr', fontWeight: 'bold', color: "primary.dark" }}
            >
              {t.subtitle2}
            </Typography>

            <Paper elevation={3} sx={{ p: 4, mt: 3 }}>
              <form onSubmit={handleSubmit}>
                {error && (
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {t.errorMessage}
                  </Alert>
                )}
                {/* Form fields */}
                <Box sx={{ mb: 2, height: 100, border: '1px dashed grey', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {image ? (
                    <img src={URL.createObjectURL(image)} alt="Uploaded" style={{ maxHeight: '100%', maxWidth: '100%' }} />
                  ) : (
                    <Typography>{t.uploadImage}</Typography>
                  )}
                </Box>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={handleImageUpload}
                />
                <label htmlFor="raised-button-file">
                  <Button variant="outlined" color='secondary' component="span" fullWidth>
                    {t.chooseImage}
                  </Button>
                </label>

                <RTLTextField
                  fullWidth
                  margin="normal"
                  label={t.productName}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isrtl={isRTL.toString()}
                />
                <RTLTextField
                  fullWidth
                  margin="normal"
                  label={t.shortDescription}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  isrtl={isRTL.toString()}
                />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: isRTL ? 'row-reverse' : 'row', mb: 2 }}>
                  <RTLTextField
                    label={t.price}
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    sx={{ width: '48%' }}
                    isrtl={isRTL.toString()}
                    InputProps={{
                      startAdornment: !!price || !!currency ? (
                        <InputAdornment position="start">
                          {currency === 'NIS' ? '₪' : currency === 'USD' ? '$' : currency === 'EUR' ? '€' : null}
                        </InputAdornment>
                      ) : null,
                    }}
                    InputLabelProps={{
                      shrink: !!price || undefined,
                    }}
                  />
                  <StyledFormControl fullWidth isrtl={isRTL.toString()} sx={{ width: '48%' }}>
                    <InputLabel>{t.currency}</InputLabel>
                    <Select
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      {currencyOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Box>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 7, mb: 0 }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : t.addProduct}
                </Button>
              </form>
            </Paper>
          </>
        )}
      </Box>
    </Container>
  );
};

export default ProductUploadForm;
