import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// import images
import sushiTimeLogo from './img/sushiTimeLogo.svg';
import bfree from './img/bfree.svg';
import premier from './img/premier.svg';
import foodAndFruits from './img/Food And Fruits.svg';
import morLia from './img/more-lia.svg';

const logos = [
  sushiTimeLogo,
  bfree,
  premier,
  foodAndFruits,
  morLia,
];

const logoStyle = {
  width: '100px',
  height: '80px',
  margin: '0 32px',
  filter: 'grayscale(100%)'
};

const translations = {
  en: {
    title: "Trusted by the best companies",
  },
  he: {
    title: "מהימן על ידי החברות הטובות ביותר",
  },
};

export default function LogoCollection({ language = 'en' }) {
  const t = translations[language];

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        color="text.secondary"
        sx={{ direction: language === 'he' ? 'rtl' : 'ltr' }}
      >
        {t.title}
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Company logo ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}