import { useEffect } from 'react';

function AccessibilityWidget() {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('data-position', 3); // Bottom-right position
    script.setAttribute('data-color', '#6444FF');
    script.setAttribute('data-account', 'Ppv0LY4t3v');
    script.src = 'https://cdn.userway.org/widget.js';
    document.body.appendChild(script);

    return () => {
      // Clean up the script if the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component does not render anything in the DOM
}

export default AccessibilityWidget;

